import { ADD_THEME, REMOVE_THEME, RESET_THEME } from './constants';

// The initial state of this store.
const initialState = {
  theme: [],
};

export { initialState };

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_THEME: {
      const cloneTheme = [...state.theme];
      cloneTheme.unshift(action.payload.theme);

      return {
        ...state,
        theme: cloneTheme,
      };
    }
    case REMOVE_THEME: {
      const cloneTheme = [...state.theme];
      const themeIndex = cloneTheme.indexOf(action.payload.theme);

      if (themeIndex !== -1) {
        cloneTheme.splice(themeIndex, 1);
      }

      return {
        ...state,
        theme: cloneTheme,
      };
    }
    case RESET_THEME: {
      return {
        ...state,
        theme: [],
      };
    }
    default: {
      return state;
    }
  }
};
