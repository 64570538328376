import './src/assets/global.css';

import ReactDOM from 'react-dom';
import config from 'react-reveal/globals';
import type { Container } from 'react-dom';
import type { ShouldUpdateScrollArgs } from 'gatsby';
import type { ReactElement } from 'react';

config({ ssrFadeout: true });

export function shouldUpdateScroll({ routerProps: { location } }: ShouldUpdateScrollArgs) {
  // Anchor links should not reset the scroll of the page
  if (location.hash !== '') return;

  window.scrollTo(0, 0);
  const body = document.getElementsByTagName('body')[0];
  body.scrollTop = 0;
  return false;
}

// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737
export function replaceHydrateFunction() {
  return (element: ReactElement, container: Container, callback: () => void) => {
    ReactDOM.render(element, container, callback);
  };
}

export { default as wrapRootElement } from './src/state/ReduxWrapper';
