interface IEDocument extends Document {
  documentMode: any;
}

export const INITIAL_STATE_KEY = '__INITIAL_STATE__';

export const inServer = typeof window === 'undefined';

export const inBrowser = typeof window !== 'undefined';

export const isMobile = inBrowser && window.orientation > -1;

// @todo: Check that !!(document as IEDocument).documentMode) successfully finds IE browsers
export const isIE = /*@cc_on!@*/ false || (!inServer && !!(document as IEDocument).documentMode);

export const isDevelopment = process.env.NODE_ENV === 'development';

export const isIOS = inBrowser && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');
