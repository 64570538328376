import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';

import { INITIAL_STATE_KEY } from '../config/theme/constants';
import { composeWithDevTools } from 'redux-devtools-extension';
import site from './Site/reducers';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  site,
});

export const initiateStore = (initialState, middleWares = []) =>
  createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk, ...middleWares)));

let store = null;

// added so we don't error out when compiling for SSR
if (typeof window !== 'undefined') {
  store = store || initiateStore(window[INITIAL_STATE_KEY] && JSON.parse(window[INITIAL_STATE_KEY]));
  delete window[INITIAL_STATE_KEY];
} else {
  // When we are creating a store for the SSR..
  store = store || initiateStore();
}

export default store;

export const getStore = () => store;
